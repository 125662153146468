import classNames from 'classnames'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import Icon from '@/components/Icon/Icon'
import cookieService from '@/services/cookie.service'
import { useAppSelector } from '@/store/hooks'
import useDirectories from '@/utils/useDirectories'

import styles from './AsteriAd.module.scss'

const maxAge = 300

const AsteriAd = () => {
  const region = useAppSelector((state) => state?.user.region)
  const [cookies, _] = useCookies(['asteriClosed'])
  const { dir } = useDirectories()
  const [isClient, setIsClient] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
    cookieService.set('asteriClosed', true, { maxAge })
    setTimeout(() => {
      setIsOpen(true)
      cookieService.remove('asteriClosed')
    }, 1000 * maxAge)
  }

  useEffect(() => {
    setIsClient(true)
    setIsOpen(cookies.asteriClosed !== true)
  }, [])

  if (!isClient || !isOpen || region !== 'US') return null

  return (
    <>
      {region === 'US' && (
        <Script
          async
          src={`https://asteriresearch.com/livestream-latest.min.js?v=${new Date().getTime()}`}
          type='text/javascript'
        />
      )}
      <div className={classNames(styles.container, { [styles.index]: !dir })}>
        <div className='h-100 position-relative w-100'>
          <Icon className={styles.close} icon='times' onClick={handleClose} />
          <div
            // @ts-ignore
            asterilivestream_height={window.innerWidth < 768 ? 90 : 250}
            asterilivestream_profile='106_86'
            asterilivestream_width={window.innerWidth < 768 ? 160 : 300}
          />
        </div>
      </div>
    </>
  )
}

export default AsteriAd
